import React, { useState } from 'react';
import {
  Card,
  FormGroup,
  InputGroup,
  Button,
  Intent,
  Callout,
  Tooltip,
} from '@blueprintjs/core';

const EyeButton = ({ isVisible, toggle }) => (
  <Tooltip content={isVisible ? 'Masquer' : 'Afficher'}>
    <Button
      icon={isVisible ? 'eye-off' : 'eye-on'}
      intent={Intent.PRIMARY}
      onClick={() => toggle(!isVisible)}
      minimal
    />
  </Tooltip>
);

export const CustomLoginFormRenderer = ({
  submit,
  setLogin,
  setPassword,
  errorLogin,
  errorPassword,
  errorGeneric,
  env,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Card className="login-form">
      {errorGeneric && (
        <Callout className="login-form__callout" intent={Intent.DANGER}>
          Informations invalides
        </Callout>
      )}
      <form
        onSubmit={submit}
      >
        <FormGroup
          helperText="Entrez votre adresse email"
          label="Email"
          labelFor="login"
          labelInfo="requis"
          intent={errorLogin ? Intent.WARNING : null}
        >
          <InputGroup
            type="email"
            id="login"
            placeholder="Email"
            onChange={setLogin}
            intent={errorLogin ? Intent.WARNING : null}
            autoComplete="username"
          />
        </FormGroup>

        <FormGroup
          helperText="Entrez votre mot de passe"
          label="Mot de passe"
          labelFor="password"
          labelInfo="requis"
          intent={errorPassword ? Intent.WARNING : null}
        >
          <InputGroup
            type={showPassword ? 'text' : 'password'}
            id="password"
            placeholder="Mot de passe"
            onChange={setPassword}
            intent={errorPassword ? Intent.WARNING : null}
            autoComplete="current-password"
            rightElement={<EyeButton isVisible={showPassword} toggle={setShowPassword} />}
          />
        </FormGroup>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <a href={env.RESET_PASSWORD_URL}>Mot de passe perdu</a>
          <Button
            type="submit"
          >
            Se connecter
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default CustomLoginFormRenderer;
