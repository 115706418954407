import React from 'react';
import { Card } from '@blueprintjs/core';

import logoFedescotMinistere from '../../../images/logo_fedescot_ministere.png';

const logosPath = [
  {
    name: 'FedeSCOT',
    path: 'http://www.fedescot.org/',
    logo: logoFedescotMinistere,
  }
];



const PartnerPage = () => (
  <Card>
    <div className="mentions-legales">
      <h3 className="mentions-legales-title">Mentions légales</h3>
      <div className="mentions-legales-container">
        <p>
        <b>Le Centre de Ressources - SCoT en Action - est édité par la Fédération nationale des SCoT (FédéSCoT) 
        </b></p>
        <p>
        <b>en partenariat avec le Ministère de la Cohésion des Territoires et des Relations avec les Collectivités territoriales (MCTRCT).
        </b></p>

    <div className='partner-logo-container'>
      {logosPath.map(({ name, path, logo }) => (
        <a key={name} href={path} target='_blank' rel='noopener noreferrer'>
          <img className='partner-logo' src={logo} label={name} alt={name} class="center"/>
        </a>
      ))}
    </div>

        <div className="container-block">
          <h4>Avertissement</h4>
          <p>
          Les données millésimées d’une année N sont prises en compte au 1er janvier de l’année N+1. 
          Ainsi, l’année 2021 est basée sur les données récoltées au 31 décembre 2020.
          </p>
          <p>
          Les données 2012 à 2020 de la vue Cartographie sont issues de la base SUDOCUH du Ministère (MCTRCT).
          </p>
          <p>
          Ce Centre de Ressources SCoT en Action est alimenté par les Responsables de SCoT dans les territoires.
          </p>
        </div>

        <div className="container-block">
          <h4>Réalisation du Centre de Ressources</h4>
          <p>
          Makina Corpus et FédéSCoT
          </p>
        </div>

        <div className="container-block">
          <h4>Contact</h4>
          <address>
            <p>Fédération nationale des SCoT</p>
            <p>22 rue Joubert</p>
            <p>75009 PARIS</p>
            <p>Tél&nbsp;: <a href="tel:+33140418410">01.40.41.84.10</a></p>
            <p>Site&nbsp;: <a href="http://www.fedescot.org/">www.fedescot.org</a></p>
           <p>
  <br />

</p>
            <p>Référente : Cécile GONDARD</p>
            <p>Email&nbsp;: <a href="mailto:cecile.gondard@fedescot.org">cecile.gondard@fedescot.org</a></p>
          </address>
        </div>

      </div>
    </div>
  </Card>
);

export default PartnerPage;
